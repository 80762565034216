<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Report classification</span>
  </span>
</header>

<mat-dialog-content>
  <span *ngIf="!currentUser.privacyPolicy">
    In order to send a report it is necessary to have accepted the privacy
    policy.
  </span>

  <form [formGroup]="reportForm" novalidate>
    <mat-form-field class="u-full-width">
      <mat-label>Type</mat-label>
      <mat-select formControlName="requestType">
        <mat-option value="image">Image</mat-option>
        <mat-option value="video">Video</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="file-field w-full">
      <ngx-mat-file-input formControlName="file" placeholder="Upload File" [accept]="acceptsString">
        <mat-icon ngxMatFileInputIcon color="primary" style="font-size: 1.5rem">folder</mat-icon>
      </ngx-mat-file-input>

      <mat-error> {{ reportForm.get("file").errors?.error }}</mat-error>
      <mat-hint>The maximum allowed size is {{ maxSize | byteFormat }}</mat-hint>
    </mat-form-field>

    <mat-form-field class="u-full-width">
      <mat-label>Classification</mat-label>
      <mat-select formControlName="classification" required>
        <mat-option value="human">Human</mat-option>
        <mat-option value="artificial">Artificial</mat-option>
      </mat-select>
      <mat-error>Field required</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="primary" (click)="send()" [disabled]="reportForm.invalid">
      Submit
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>
