import { GraphMultiSeries } from 'src/app/helpers/graph.utils';
import { environment } from 'src/environments/environment';

import { Base, BaseDTO } from './base.model';
import { ClassificationResult, ClassificationType } from './request.model';

export type ImagesGraphData = GraphMultiSeries[];

export interface ImageGraphFilters {
  start?: string;
  end?: string;
}

export interface VerdictDTO {
  heuristic: string;
  classification: ClassificationType;
  confidence: number;
  models: string[];
}

export interface ImageDTO extends BaseDTO {
  hash: string;
  name?: string;
  path?: string;
  classification?: ClassificationType;
  results: ClassificationResult[];
  // force_classification?: ClassificationType;
  false_positive: boolean;
  confidence?: number;
  version?: string;
  user_id: number;

  c2pa: CPADetails;
  verdicts: VerdictDTO[];
}

export interface CPADetails {
  active_manifest: string,
  manifests: {
    [key: string]: CPAManifest
  }
}

export interface CPAManifest {
  title: string,
  format: string,
  claim_generator: string,
  claim_generator_info: [
    {
      name: string,
      version: string
    }
  ],
  assertions: [
    {
      label: string,
      data: {
        actions: [
          {
            action: string,
            softwareAgent: string,
            when: string
          }
        ]
      }
    }
  ],
  ingredients: [],
  signature_info: {
    issuer: string,
    cert_serial_number: string,
    time: string
  },
}

export class Image extends Base {
  hash: string;
  name?: string;
  private _path?: string;
  legacyClassification?: ClassificationType;
  // forceClassification: ClassificationType;
  falsePositive: boolean;
  legacyConfidence?: number;
  version?: string;
  userId: number;
  details: CPADetails;
  results: ClassificationResult[];
  verdicts: VerdictDTO[];

  constructor(source: ImageDTO) {
    super(source);
    if (source) {
      this.hash = source.hash;
      this.name = source.name;
      this._path = source.path;
      this.legacyClassification = source.classification;
      this.falsePositive = source.false_positive;
      this.legacyConfidence = source.confidence;
      this.version = source.version;
      this.userId = source.user_id;
      this.details = source.c2pa;
      this.results = source.results;
      this.verdicts = source.verdicts;
    }
  }

  toDTO(): ImageDTO {
    let result: ImageDTO = <ImageDTO>super.toDTO();
    result.hash = this.hash;
    result.name = this.name;
    result.path = this._path;
    result.classification = this.legacyClassification;
    // result.force_classification = this.forceClassification;
    result.confidence = this.legacyConfidence;
    result.version = this.version;
    result.user_id = this.userId;
    result.c2pa = this.details;
    result.results = this.results;
    result.verdicts = this.verdicts;
    return result;
  }

  get path(): string {
    if (this._path)
      return environment.baseUrl + '/api/store/' + this._path;
  }

  get CPAManifest(): CPAManifest {
    if (this.details) {
      return this.details.manifests[this.details.active_manifest];
    }
    return null;
  }

  get CPAActions(): {
    action: string,
    softwareAgent: string,
    when: string
  }[] {
    if (this.CPAManifest) {
      return this.CPAManifest.assertions.filter(assertion => assertion.data.actions?.length).reduce((actions, assertion) => actions.concat(assertion.data.actions), []);
    }
    return [];
  }

  getVerdict(heuristic?: string): VerdictDTO | undefined {
    if (this.verdicts?.length) {
      return this.verdicts.find(v => v.heuristic == heuristic) || this.verdicts[0];
    }
    return undefined;
  }

  getClassification(heuristic?: string): ClassificationType {
    return this.getVerdict(heuristic)?.classification || this.legacyClassification;
  }

  getConfidence(heuristic: string): number {
    return this.getVerdict(heuristic)?.confidence || this.legacyConfidence;
  }
}
