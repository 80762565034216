import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/commons/models/user.model';
import {
  IMG_MAX_SIZE,
  IMG_VALID_EXTENSIONS,
  VIDEO_MAX_SIZE,
  VIDEO_VALID_EXTENSIONS,
} from 'src/app/constants/media.constants';
import { fileExtensionValidator, fileSizeValidator } from 'src/app/helpers/validators/file.validator';
import * as ReportActions from 'src/app/store/actions/report.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: "identifai-classification-report",
  templateUrl: "./classification-report.component.html",
  styleUrls: ["./classification-report.component.scss"],
})
export class ClassificationReportComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  reportForm: UntypedFormGroup = new UntypedFormGroup({
    requestType: new UntypedFormControl(null, [Validators.required]),
    file: new UntypedFormControl(null, [
      Validators.required, fileExtensionValidator(IMG_VALID_EXTENSIONS), fileSizeValidator(IMG_MAX_SIZE)
    ]),
    classification: new UntypedFormControl(null, [Validators.required]),
  });

  maxSize = IMG_MAX_SIZE;
  acceptsString: string = IMG_VALID_EXTENSIONS.map((v) => `.${v}`).join(',')

  currentUser: User;

  constructor(
    private store$: Store<AppState>,
  ) {

    this.reportForm.get('requestType').valueChanges.subscribe(type => {
      if (type === 'image') {
        this.maxSize = IMG_MAX_SIZE;
        this.acceptsString = IMG_VALID_EXTENSIONS.map((v) => `.${v}`).join(',');
        this.reportForm.get('file').setValidators([Validators.required, fileExtensionValidator(IMG_VALID_EXTENSIONS), fileSizeValidator(IMG_MAX_SIZE)]);
      } else {
        this.maxSize = VIDEO_MAX_SIZE;
        this.acceptsString = VIDEO_VALID_EXTENSIONS.map((v) => `.${v}`).join(',');
        this.reportForm.get('file').setValidators([Validators.required, fileExtensionValidator(VIDEO_VALID_EXTENSIONS), fileSizeValidator(VIDEO_MAX_SIZE)]);
      }
      this.reportForm.get('file').updateValueAndValidity();
    })
    this.store$.select(AuthSelectors.getCurrentUser).pipe(
      takeUntil(this.unsubscribe$),
      map(dto => dto ? new User(dto) : null),
    ).subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  send() {
    let { requestType, file, classification } = this.reportForm.value;
    this.store$.dispatch(ReportActions.sendReport({ requestType, file, classification }));
  }

  close() {
    this.store$.dispatch(ReportActions.closeReportDialog());
  }
}
