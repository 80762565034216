import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { CreditFilters } from 'src/app/commons/models/credit.model';
import { AppState } from 'src/app/store/reducers';
import * as CreditSelectors from 'src/app/store/selectors/credit.selectors';

@Component({
  selector: 'credit-filters',
  templateUrl: './credit-filters.component.html',
  styleUrls: ['./credit-filters.component.scss']
})
export class CreditFiltersComponent {
  @Output()
  onFilter: EventEmitter<CreditFilters> = new EventEmitter();

  private _defaultFilters: CreditFilters;

  filtersForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      user: [""],
      amount: [""],
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getCreditFilters());
  }

  private _getCreditFilters(): CreditFilters {
    let filters: CreditFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.amount = values.amount;
      filters.user = values.user;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      amount: null,
      user: null
    });
    this.applyFilters();
  }

  get defaultFilters(): CreditFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: CreditFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          amount: this.defaultFilters.amount,
          user: this.defaultFilters.user
        })
      }
    }
  }
}
