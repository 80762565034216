<header mat-dialog-title class="u-flex-full">
  <span> Select user </span>
</header>
<mat-dialog-content>
  <user-list
    [users]="users"
    [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns"
    [total]="total"
    [canSelect]="true"
    (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)"
    (onSelectUser)="selectUser($event)"
  ></user-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span> </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>
