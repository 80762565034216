<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field *showFor="['admin']" class="u-full-width">
        <mat-label>User</mat-label>
        <identifai-user-field formControlName="user"></identifai-user-field>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Search</mat-label>
        <input matInput formControlName="search" />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option value="">-- All --</mat-option>
          <mat-option value="image">Image</mat-option>
          <mat-option value="video">Video</mat-option>
        </mat-select>
      </mat-form-field>
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">
          Filter
        </button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>

    <div class="u-flex-full">
      <!-- <mat-form-field class="u-full-width">
        <mat-label>Image</mat-label>
        <ngx-mat-file-input formControlName="image" placeholder="Image" [accept]="acceptsString">
          <mat-icon ngxMatFileInputIcon hidden color="primary" style="font-size: 1.5rem">folder</mat-icon>
        </ngx-mat-file-input>
        <mat-error>
          {{ this.filtersForm.get("image").errors?.error }}</mat-error>
        <mat-hint>The maximum allowed size is {{ maxSize | byteFormat }}</mat-hint>
      </mat-form-field> -->


    </div>
  </form>
</mat-expansion-panel>