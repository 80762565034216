import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/commons/models/user.model';
import { Logo } from 'src/app/constants/assets.constants';
import { logout } from 'src/app/store/actions/auth.actions';
import * as RouterActions from 'src/app/store/actions/router.actions';
import * as SidebarActions from 'src/app/store/actions/sidebar.actions';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import { getCurrentUser } from 'src/app/store/selectors/auth.selectors';
import * as SidebarSelector from 'src/app/store/selectors/sidebar.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: "identifai-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
})
export class TopnavComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  isSidebarOpen: Observable<boolean>

  url: string;
  currentUser: User;
  logo = Logo;
  beta: boolean = environment.beta;

  @Output()
  onToggleSidebar: EventEmitter<any> = new EventEmitter();

  constructor(private store$: Store<AppState>) {
    this.isSidebarOpen = this.store$.pipe(select(SidebarSelector.getIsToggled), takeUntil(this.unsubscribe$));
  }

  ngOnInit() {
    this.store$
      .pipe(
        select(getCurrentUser),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new User(dto) : null))
      )
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  toggleSidebar(event: any) {
    event.stopPropagation() // stop the "@HostListener('document:click..." of the "sidebar.component.ts"
    this.store$.dispatch(SidebarActions.sidebarToggle());
    this.onToggleSidebar.emit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.store$.dispatch(logout({ showConfirm: true }));
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector("body");
    return dom.classList.contains("push-right");
  }

  changePassword() {
    if (this.currentUser) {
      this.store$.dispatch(
        UserActions.changeUserPassword({ user: this.currentUser.toDTO() })
      );
    }
  }

  editCurrentUser() {
    if (this.currentUser) {
      this.store$.dispatch(UserActions.editCurrentUser());
    }
  }

  apiKey() {
    this.store$.dispatch(
      UserActions.openApiKeyDialog({ user: this.currentUser })
    );
  }

  goHome() {
    this.store$.dispatch(RouterActions.routerGo({ path: ['/'] }));
  }
}
