import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Report, ReportFilters } from 'src/app/commons/models/report.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';

export type ReportsColumn = "type" | "hash" | "id" | "user" | "classification" | "solved";

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  reports: Observable<Report[]>;

  @Input()
  defaultFilters: ReportFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ReportsColumn[] = ["type", "hash", "user", "solved", "classification"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ReportFilters> = new EventEmitter<ReportFilters>();

  @Output()
  onAddReport: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onShowReport: EventEmitter<Report> = new EventEmitter<Report>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addReport() {
    this.onAddReport.emit();
  }

  showReport(report: Report) {
    this.onShowReport.emit(report);
  }

  onFilter(filters: ReportFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
