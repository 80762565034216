<credit-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</credit-filters>
<mat-card class="u-center-text" *ngIf="!(credits | async).length">
  <h4>No transaction found</h4>
  <button
    *showFor="['admin']"
    mat-raised-button
    color="primary"
    (click)="addCredit()"
  >
    Add Credit
  </button>
</mat-card>
<div [hidden]="!(credits | async).length" class="table-container">
  <table mat-table matSort [dataSource]="credits">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let credit">{{ credit.id }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell mat-sort-header="created_at" *matHeaderCellDef>
        Date
      </th>
      <td mat-cell *matCellDef="let credit">
        {{ credit.createdAt | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="positive">
      <th mat-header-cell *matHeaderCellDef>Recharge</th>
      <td mat-cell *matCellDef="let credit" class="positive">
        {{ credit.amount > 0 ? credit.amount : null }}
      </td>
    </ng-container>

    <ng-container matColumnDef="negative">
      <th mat-header-cell *matHeaderCellDef>Usage</th>
      <td mat-cell *matCellDef="let credit" class="negative">
        {{ credit.amount < 0 ? credit.amount : null }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let credit">
        {{ credit?.user?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hash">
      <th mat-header-cell *matHeaderCellDef>Hash</th>
      <td mat-cell *matCellDef="let credit">
        {{ credit.imageHash }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let credit">
        {{ credit.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *showFor="['admin']">
          <button
            mat-icon-button
            mat-raised-button
            color="primary"
            (click)="$event.stopPropagation(); addCredit()"
          >
            <mat-icon>playlist_add</mat-icon>
          </button>
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let credit"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="editCredit(row)"
    ></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSizeOptions[0]"
    [length]="total | async"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
