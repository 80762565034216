import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { checkIfMatchingPasswords, passwordValidator } from 'src/app/helpers/validators/password.validator';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'identifai-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  passwordForm: UntypedFormGroup;

  user: User;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: UntypedFormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    if (this.data && this.data.user) {
      this.user = new User(this.data.user);
    }
    this._createForm();
  }

  private _createForm() {
    let group = {
      newPass: ["", [Validators.required, passwordValidator]],
      confirmPass: ["", Validators.required]
    };

    this.passwordForm = this.fb.group(group, {
      validator: checkIfMatchingPasswords("newPass", "confirmPass")
    });
  }

  private ngOnChanges() {
    if (this.passwordForm) {
      this.passwordForm.reset();
    }
  }

  updatePassword() {
    this.store$.dispatch(UserActions.updatePassword({ newPassword: this.passwordForm.get('newPass').value, user: this.user ? this.user.toDTO() : null }));
  }

  close() {
    if (this.passwordForm.pristine) {
      this.store$.dispatch(UserActions.closeChangePasswordDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Close",
          "There are unsaved changes. Are you sure you want to close?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }

  private doClose() {
    this.store$.dispatch(UserActions.closeChangePasswordDialog());
  }
}








