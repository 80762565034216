import { Pipe, PipeTransform } from '@angular/core';
import { ClassificationType } from 'src/app/commons/models/request.model';

@Pipe({
  name: 'classification'
})
export class ClassificationPipe implements PipeTransform {

  transform(value: ClassificationType): unknown {
    switch (value) {
      case 'human':
        return 'Human';
      case 'artificial':
        return 'Artificial';
    }
  }

}
