import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Role, UserFilters } from 'src/app/commons/models/user.model';

@Component({
  selector: 'identifai-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss']
})
export class UserFiltersComponent {

  roles: Role[] = ["admin", "client"];

  @Output()
  onFilter: EventEmitter<UserFilters> = new EventEmitter();

  private _defaultFilters: UserFilters;
  filtersForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      roles: [["admin", "client"]]
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getUserFilters());
  }

  private _getUserFilters(): UserFilters {
    let filters: UserFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.roles = values.roles;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      roles: []
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): UserFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: UserFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          roles: this.defaultFilters.roles
        })
      }
    }
  }
}
