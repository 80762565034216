<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Api-Key</span>
  </span>
</header>
<mat-dialog-content class="u-full-width">
  <div class="u-full-width" style="margin-bottom: 1.5rem">
    <span class="u-flex-full">
      <mat-form-field>
        <mat-label>Api-Key</mat-label>
        <input
          [type]="hide ? 'password' : 'text'"
          matInput
          [value]="user?.apiKey"
          readonly
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? "visibility" : "visibility_off"
        }}</mat-icon>
      </mat-form-field>
    </span>
  </div>

  <span>Old Api-Keys</span>
  <api-key-list
    [apiKeys]="apiKeys"
    (onSortChange)="(sortChange)"
  ></api-key-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="primary" (click)="resetApiKey()">
      {{ user?.apiKey ? "Reset" : "Generate" }}
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>
