import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { ClassificationResult, ClassificationType } from 'src/app/commons/models/request.model';
import { deepMerge, DEFAULT_OPTIONS, GraphData, mapDataForGraph } from 'src/app/helpers/graph.utils';

@Component({
  selector: 'identifai-image-models',
  templateUrl: './image-models.component.html',
  styleUrls: ['./image-models.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageModelsComponent implements OnChanges {

  @Input()
  results: ClassificationResult[];

  @Input()
  verdict: ClassificationType = 'human';

  // chartConfig = ResultsBarGraph;

  graphData: GraphData;

  graphConfig: ChartOptions = deepMerge(DEFAULT_OPTIONS, {
    scales: {
      x: {
        title: {
          display: true,
          text: "Model"
        }
      },
      y: {
        max: 100,
        min: 0,
      }
    }
  });

  ngOnChanges(changes: SimpleChanges): void {
    const data = this.results.map(result => {
      const name = result.model;
      const right = result.classification.find(c => c.label === this.verdict);
      const wrong = result.classification.find(c => c.label !== this.verdict);

      return {
        name, series: [{ name: right.label, value: right.score * 100 }, { name: wrong.label, value: wrong.score * 100 }]
      }
    });

    this.graphData = mapDataForGraph(data);

  }
}
