import { Action, createReducer, on } from '@ngrx/store';

import * as ImageActions from '../actions/image.actions';
import { ImagesGraphData } from 'src/app/commons/models/image.model';
import { VideosGraphData } from 'src/app/commons/models/video.model';

export interface ImageState {
  imagesGraphData: ImagesGraphData
  videosGraphData: VideosGraphData
};

const initialState: ImageState = {
  imagesGraphData: null,
  videosGraphData: null
};

const userReducer = createReducer(
  initialState,
  on(ImageActions.loadImagesGraphCompleted, (state, { result: graphData }): ImageState => {
    return { ...state, imagesGraphData: graphData };
  }),
  on(ImageActions.loadVideosGraphCompleted, (state, { result: graphData }): ImageState => {
    return { ...state, videosGraphData: graphData };
  })
);

export function reducer(state: ImageState | undefined, action: Action) {
  return userReducer(state, action);
}

