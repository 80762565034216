import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';

import { LaravelUserService } from '../../commons/services/backend/laravel-user.service';
import * as AuthActions from '../actions/auth.actions';
import { AppState } from '../reducers';
import * as AuthSelectors from '../selectors/auth.selectors';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap(({ username, password }) =>
        this.laravelUserService.login(username, password)
          .pipe(
            map(result => AuthActions.loginCompleted({ currentUser: result.user })),
            catchError(error => of(AuthActions.loginFailed({ error }))))
      ))
  }
  );

  afterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginCompleted),
      tap(() => {
        this.router.navigate(['/']);
        this.alertService.showConfirmMessage(`Logged in successfully`)
      }))
  }, { dispatch: false },
  );


  loginError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginFailed),
      tap((action) => this.alertService.showErrorMessage(`Error during login`, action.error))
    )
  }, { dispatch: false }
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(({ showConfirm }) => {
        if (showConfirm) {
          return this.alertService.showConfirmDialog('Confirm', `Are you sure you want to logout?`)
            .pipe(
              switchMap((confirm) => {
                return confirm
                  ? this.laravelUserService.logout()
                    .pipe(
                      map(() => AuthActions.logoutCompleted()),
                      catchError((error) =>
                        of(AuthActions.logoutFailed({ error }))
                      )
                    )
                  : of(AuthActions.logoutCancelled());
              })
            );
        } else {
          return of(AuthActions.logoutCompleted());
        }
      })
    )
  });

  afterLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutCompleted),
      tap(() => this.router.navigate(['login'])),
      tap(() => this.alertService.showConfirmMessage(`Logged out successfully`))
    )
  },
    { dispatch: false }
  );

  currentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCurrentUser),
      mergeMap(() =>
        this.laravelUserService.getCurrentUser()
          .pipe(
            map((currentUser) => AuthActions.loadCurrentUserCompleted({ currentUser })),
            catchError(error => of(AuthActions.loadCurrentUserFailed({ error }))))
      )
    )
  });

  loadConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserCompleted, AuthActions.loginCompleted),
      map(() =>
        AuthActions.loadAvailabelHeuristics()
      )
    )
  })

  loadAvailabelHeuristics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadAvailabelHeuristics),
      mergeMap(() =>
        this.laravelUserService.loadHeuristics()
          .pipe(
            map((heuristics) => AuthActions.loadAvailabelHeuristicsCompleted({ heuristics })),
            catchError(error => of(AuthActions.loadAvailabelHeuristicsFailed({ error }))))
      ))
  }
  );

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      mergeMap(({ email }) =>
        this.laravelUserService.forgotPassword(email)
          .pipe(
            map(() => AuthActions.forgotPasswordCompleted()),
            catchError(error => of(AuthActions.forgotPasswordFailed({ error }))))
      ))
  }
  );

  forgotPasswordCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPasswordCompleted),
      tap(() => {
        this.alertService.showConfirmMessage(`Email sent successfully, please check your inbox.`)
      }))
  }, { dispatch: false },
  );


  forgotPasswordFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPasswordFailed),
      tap((action) => this.alertService.showErrorMessage(`Error`, action.error))
    )
  }, { dispatch: false }
  );

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      mergeMap(({ token, email, password, passwordConfirmation }) =>
        this.laravelUserService.resetPassword(token, email, password, passwordConfirmation)
          .pipe(
            map(result => AuthActions.resetPasswordCompleted()),
            catchError(error => of(AuthActions.resetPasswordFailed({ error }))))
      ))
  }
  );

  resetPasswordCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPasswordCompleted),
      tap(() => {
        this.router.navigate(['/login']);
        this.alertService.showConfirmMessage(`Password reset successfully.`)
      }))
  }, { dispatch: false },
  );

  resetPasswordFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPasswordFailed),
      tap((action) => this.alertService.showErrorMessage(`Error`, action.error))
    )
  }, { dispatch: false }
  );

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.register),
      mergeMap(({ name, email, password, tos }) =>
        this.laravelUserService.register(name, email, password, tos)
          .pipe(
            map(result => AuthActions.registerCompleted()),
            catchError(error => of(AuthActions.registerFailed({ error }))))
      ))
  }
  );

  afterRegister$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.registerCompleted),
      tap(() => {
        this.alertService.showConfirmMessage(`Registered successfully, please check your inbox and verifiy your email.`)
      }))
  }, { dispatch: false },
  );


  registerError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.registerFailed),
      tap((action) => this.alertService.showErrorMessage(`Error during registration`, action.error))
    )
  }, { dispatch: false }
  );

  updateCurrentHeuristic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateCurrentHeuristic),
      withLatestFrom(this.store$.select(AuthSelectors.getCurrentUser)),
      filter(([_, currentUser]) => !!currentUser),
      map(([{ heuristic }, currentUser]) => AuthActions.updateHeuristic({ userId: currentUser.id, heuristic })),
    )
  })

  updateHeuristic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateHeuristic),
      mergeMap(({ userId, heuristic }) =>
        this.laravelUserService.saveHeuristic(userId, heuristic)
          .pipe(
            map(user => AuthActions.updateHeuristicCompleted({ user })),
            catchError(error => of(AuthActions.verifyEmailFailed({ error }))))
      ))
  });

  verifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyEmail),
      mergeMap(({ token }) =>
        this.laravelUserService.verifyEmail(token)
          .pipe(
            map(result => AuthActions.verifyEmailCompleted()),
            catchError(error => of(AuthActions.verifyEmailFailed({ error }))))
      ))
  }
  );

  verifyEmailCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyEmailCompleted),
      tap(() => {
        this.router.navigate(['/login']);
        this.alertService.showConfirmMessage(`Email verified successfully.`)
      }))
  }, { dispatch: false },
  );

  verifyEmailFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyEmailFailed),
      tap((action) => this.alertService.showErrorMessage(`Error`, action.error))
    )
  }, { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private laravelUserService: LaravelUserService,
    private alertService: AlertService,
    private router: Router
  ) { }
}
