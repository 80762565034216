import { createSelector } from '@ngrx/store';

import { selectAuthState } from '../reducers';
import { AuthState } from '../reducers/auth.reducers';

export const isAdmin = createSelector(
  selectAuthState,
  (state: AuthState) => state.currentUser?.user_roles?.includes('admin')
);

export const getCurrentUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.currentUser
);

// export const getAuthToken = createSelector(
//   selectAuthState,
//   (state: AuthState) => state.token
// );

export const getIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => !!state.currentUser
);

export const getApiKey = createSelector(
  selectAuthState,
  (state: AuthState) => state.currentUser?.api_key
);

export const getHeuristics = createSelector(
  selectAuthState,
  (state: AuthState) => state.heuristics
)
