import { Component, Inject, OnDestroy, OnInit, Optional } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Credit } from "src/app/commons/models/credit.model";
import { AlertService } from "src/app/commons/services/alert.service";
import * as CreditActions from "src/app/store/actions/credit.actions";
import { AppState } from "src/app/store/reducers";
import * as AuthSelectors from "src/app/store/selectors/auth.selectors";

@Component({
  selector: "identifai-credit-request",
  templateUrl: "./credit-request.component.html",
  styleUrls: ["./credit-request.component.scss"],
})
export class CreditRequestComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  amountCtrl: UntypedFormControl = new UntypedFormControl(null, [
    Validators.required, Validators.min(1),
  ]);

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  send() {
    let amount = this.amountCtrl.value;
    this.store$.dispatch(CreditActions.requestCredit({ amount }));
  }

  close() {
    this.store$.dispatch(CreditActions.closeRequestCreditDialog());
  }
}
