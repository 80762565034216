import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import * as RouterActions from 'src/app/store/actions/router.actions';

import { AppState } from '../../store/reducers';

@Injectable({
  providedIn: "root"
})
export class AuthGuard  {
  constructor(
    private store: Store<AppState>
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(AuthSelectors.getIsAuthenticated),
      take(1),
      map(authenticated => {
        if (!authenticated) {
          this.store.dispatch(RouterActions.routeChange({ path: '/login', params: [] }));
        }
        return authenticated;
      })
    );
  }
}
