import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { map, Subject, takeUntil } from 'rxjs';
import { Request } from 'src/app/commons/models/request.model';
import { User } from 'src/app/commons/models/user.model';
import * as AuthActions from 'src/app/store/actions/auth.actions';
import { openReportDialog } from 'src/app/store/actions/report.actions';
import * as RequestActions from 'src/app/store/actions/request.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: "identifai-request-info",
  templateUrl: "./request-info.component.html",
  styleUrls: ["./request-info.component.scss"],
})
export class RequestInfoComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  request: Request;
  currentUser: User;

  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store$.select(AuthSelectors.getCurrentUser).pipe(
      takeUntil(this.unsubscribe$),
      map(dto => dto ? new User(dto) : null),
    ).subscribe(user => {
      this.currentUser = user;
    });
  }
  ngOnInit(): void {
    this.request = this.data.request;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.store$.dispatch(RequestActions.closeRequestDialog());
  }

  updateHeuristic(heuristic: string) {
    if (heuristic) {
      this.store$.dispatch(AuthActions.updateCurrentHeuristic({ heuristic }));
    }
  }

  downloadC2PA() {
    if (!!this.request.image?.details) {
      const filename = `${this.request.name}_c2pa.json`;
      this.store$.dispatch(RequestActions.saveJsonToFile({ data: this.request.image.details, filename }));
    }
  }

  downloadResults() {
    if (!!this.request.results) {
      const filename = `${this.request.name}_results.json`;
      this.store$.dispatch(RequestActions.saveJsonToFile({ data: this.request.results, filename }));
    }
  }

  report() {
    this.close();
    this.store$.dispatch(openReportDialog());
  }
}
