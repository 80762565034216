import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/commons/models/user.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';

import { UsersColumn } from '../user-list/user-list.component';
import { UserFilters } from 'src/app/commons/models/user.model';

@Component({
  selector: 'identifai-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss'],
})
export class UserSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  users: Observable<User[]>;
  filters: Observable<UserFilters>;

  @Input()
  defaultFilters: UserFilters = {
    search: '',
  };

  displayedColumns: UsersColumn[] = [
    'name',
    'email',
    'role',
    'actions',
  ];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.users = this.store$.pipe(
      select(UserSelectors.getUsers),
      takeUntil(this.unsubscribe$),
      map((dtos) => (dtos ? dtos.map((dto) => new User(dto)) : null))
    );
    this.total = this.store$.pipe(
      select(UserSelectors.getTotalUsers),
      takeUntil(this.unsubscribe$)
    );
    this.filters = this.store$.pipe(
      select(UserSelectors.getFilters),
      takeUntil(this.unsubscribe$)
    );
    if (data && data.defaultFilters) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(
      UserActions.loadUsers({
        page: 1,
        perPage: this.pageSizeOptions[0],
        filters: this.defaultFilters,
      })
    );
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(
      UserActions.changeSort({ order: sort.active, direction: sort.direction })
    );
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(
      UserActions.changePage({
        page: pageEvent.pageIndex + 1,
        size: pageEvent.pageSize,
      })
    );
  }

  filtersChange(filters: UserFilters) {
    this.store$.dispatch(UserActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectUser(user: User) {
    if (user) {
      this.store$.dispatch(UserActions.userSelected({ user: user.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(UserActions.closeSelectionDialog());
  }
}
