import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { User } from 'src/app/commons/models/user.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import { UserFilters } from 'src/app/commons/models/user.model';
import { ApiKeyLog } from 'src/app/commons/models/api-key-log.model';

export type ApiKeyColumn = "apikey" | "created_at" | "actions";

@Component({
  selector: 'api-key-list',
  templateUrl: './api-key-list.component.html',
  styleUrls: ['./api-key-list.component.scss']
})
export class ApiKeyListComponent {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  apiKeys: Observable<ApiKeyLog[]>;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ApiKeyColumn[] = ["apikey", "created_at", "actions"];

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  showRows: boolean = false;

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
  }
}
