import { ChartDataset, ChartOptions } from 'chart.js';


export const GRAPH_PALETTE = ["#212c4a", "#394563", "#505d7b", "#677592", "#7e8daa", "#94a4c1", "#abbcd9", "#c2d4f0"]

export type GraphSerie = {
  name: string,
  value: number
};
export type GraphMultiSeries = {
  name: string,
  series: GraphSerie[]
};

export type GraphData = {
  labels: string[],
  datasets: ChartDataset[]
}

export const DEFAULT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      position: 'right',
      onClick: function (e, legendItem) {
        const index = legendItem.datasetIndex;
        const ci = this.chart;
        const alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;

        ci.data.datasets.forEach(function (e, i) {
          const meta = ci.getDatasetMeta(i);

          if (i !== index) {
            if (!alreadyHidden) {
              meta.hidden = meta.hidden === null ? !meta.hidden : null;
            } else if (meta.hidden === null) {
              meta.hidden = true;
            }
          } else if (i === index) {
            meta.hidden = null;
          }
        });

        ci.update();
      },
    }
  },
  scales: {
    x: {
      ticks:
      {
        callback: function (value) {
          const label = this.getLabelForValue(Number(value));
          if (label.length > 10)
            return label.substring(0, 10) + '...';
          return label;
        }
      },
      stacked: true,
      title: {
        display: true,
        text: "Model"
      }
    },
    y: {
      stacked: true,
      max: 100,
      min: 0,
      title: {
        display: true,
        text: "Confidence %"
      }
    }
  }
}

export function deepMerge(obj1: any, obj2: any): any {
  const output = Object.assign({}, obj1);

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && obj1[key]) {
        output[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        output[key] = obj2[key];
      }
    }
  }

  return output;
}

export function mapDataForGraph(allData: GraphMultiSeries[]): GraphData {
  const labels = allData.map(data => data.name);
  const datasets = [];

  const sets: Set<string> = new Set<string>();
  allData.map(data => {
    data.series.forEach((serie) => {
      sets.add(serie.name);
    })
  });

  allData.forEach(data => {
    Array.from(sets).forEach((setName, index) => {
      const value = data.series.find(serie => serie.name === setName)?.value || 0;
      let dataset: ChartDataset = datasets.find(d => d.label === setName);
      if (!dataset) {
        const paletteIndex = index % 2 === 0 ? index % GRAPH_PALETTE.length : Math.abs(GRAPH_PALETTE.length - index) % GRAPH_PALETTE.length;
        dataset = {
          data: [value],
          label: setName,
          backgroundColor: GRAPH_PALETTE[paletteIndex],
          borderColor: GRAPH_PALETTE[paletteIndex],
          pointBorderColor: GRAPH_PALETTE[paletteIndex],
          pointBackgroundColor: GRAPH_PALETTE[paletteIndex],
        }
        datasets.push(dataset);
      } else {
        dataset.data.push(value || 0);
      }
    })

  });

  return { labels, datasets };
}
