import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, map, of, take, takeUntil, tap } from 'rxjs';
import { User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { checkIfMatchingPasswords, passwordValidator } from 'src/app/helpers/validators/password.validator';
import * as AuthActions from 'src/app/store/actions/auth.actions';
import * as UserActions from 'src/app/store/actions/user.actions';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';
import { AppState } from 'src/app/store/reducers';
import { ApiKeyLog } from 'src/app/commons/models/api-key-log.model';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'identifai-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss']
})
export class ApiKeyComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  user: User;
  hide: boolean = true;

  apiKeys: Observable<ApiKeyLog[]>;

  constructor(private store$: Store<AppState>, private alertService: AlertService, @Optional() @Inject(MAT_DIALOG_DATA) public data: { user: User }) {
    if (this.data) {
      this.user = this.data.user;
    }
  }

  ngOnInit(): void {
    this.store$.dispatch(UserActions.loadApiKeys({ userId: this.user?.id }));

    this.apiKeys = this.store$.pipe(select(UserSelectors.getApiKeyLogs), takeUntil(this.unsubscribe$), map((dtos) => (dtos ? dtos.map((dto) => new ApiKeyLog(dto)) : null)));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  resetApiKey() {
    this.store$.dispatch(UserActions.resetApiKey({ userId: this.user?.id }));
  }

  close() {
    this.store$.dispatch(UserActions.closeApiKeyDialog())
  }

  sortChange(sort: Sort) {
  }
}








