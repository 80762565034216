<div style="height: 400px;">
  <!-- <mat-button-toggle-group [(ngModel)]="classification">
    <mat-button-toggle value="human">Human</mat-button-toggle>
    <mat-button-toggle value="artificial">Artificial</mat-button-toggle>
  </mat-button-toggle-group> -->
  <identifai-multi-series-graph type="bar" [data]="graphData" [config]="graphConfig"></identifai-multi-series-graph>
  <!-- <ngx-charts-bar-vertical-stacked [results]="data" [legend]="true" [showXAxisLabel]="chartConfig.showXAxisLabel"
    [showYAxisLabel]="chartConfig.showYAxisLabel" [xAxis]="chartConfig.xAxis" [yAxis]="chartConfig.yAxis"
    [xAxisLabel]="chartConfig.xAxisLabel" [yAxisLabel]="chartConfig.yAxisLabel" [scheme]="chartConfig.scheme">
  </ngx-charts-bar-vertical-stacked> -->

</div>