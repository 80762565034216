import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/modules/shared/modals/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: "root" })
export class AlertService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) { }

  public showConfirmMessage(
    message: string,
    snackBarConfig?: MatSnackBarConfig
  ) {
    let snackBarRef = this.snackBar.open(
      message,
      "X",
      Object.assign({ duration: 2500 }, snackBarConfig)
    );
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  public showErrorMessage(message: string, error: any) {
    let errorMessage = error || "";
    if (error instanceof HttpErrorResponse) {
      errorMessage = error.message;
    }
    if (error && error.error && error.error.error) {
      errorMessage = error.error.error;
      if (typeof errorMessage != "string") {
        errorMessage = JSON.stringify(errorMessage);
      }
    }
    this.showConfirmMessage(`${message} ${errorMessage}`, { duration: 2500, verticalPosition: "top", panelClass: "error" });
  }

  public showConfirmDialog(
    title: string,
    message: string
  ): Observable<any> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.confirmMessage = message;

    return dialogRef.afterClosed();
  }
}
