<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Request Credit</span>
  </span>
</header>

<mat-dialog-content>
  <form class="w-full">
    <mat-form-field class="file-field w-full">
      <input matInput [formControl]="amountCtrl" placeholder="Amount" />
      <mat-error> {{ amountCtrl.errors?.error }}</mat-error>
      <mat-hint>Amount to be requested</mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="primary"
      (click)="send()"
      [disabled]="amountCtrl.invalid"
    >
      Submit
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>
