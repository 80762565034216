import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'heuristic'
})
export class HeuristicPipe implements PipeTransform {

  transform(value: string): string {
    //["Majority","Average","AverageExclude10","AverageExclude20","AverageExclude30"]
    switch (value) {
      case "AverageExclude10":
        return "Average exclude 10%";
      case "AverageExclude20":
        return "Average exclude 20%";
      case "AverageExclude30":
        return "Average exclude 30%";
      case "TopRelevant":
        return "Top Relevant";
    }
    return value;
  }

}
