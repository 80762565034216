import { Base, BaseDTO } from "./base.model";

export interface ApiKeyLogDTO extends BaseDTO {
  user_id: number;
  api_key: string;
}

export class ApiKeyLog extends Base {
  apiKey: string;
  userId: number;

  constructor(source: ApiKeyLogDTO) {
    super(source);
    if (source) {
      this.apiKey = source.api_key;
      this.userId = source.user_id;
    }
  }
}
