<identifai-report-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</identifai-report-filters>
<mat-card class="u-center-text" *ngIf="!(reports | async)?.length">
  <h4>No report found</h4>
  <button mat-raised-button color="primary" (click)="addReport()">
    Add Report
  </button>
</mat-card>
<div [hidden]="!(reports | async)?.length" class="table-container">
  <table mat-table matSort [dataSource]="reports">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let report">
        {{ report.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hash">
      <th mat-header-cell *matHeaderCellDef>Hash</th>
      <td mat-cell *matCellDef="let report">
        {{ report.imageHash }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let report">
        {{ report.type | file_type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let report">
        {{ report.user?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="solved">
      <th mat-header-cell *matHeaderCellDef>Solved</th>
      <td mat-cell *matCellDef="let report">
        <mat-icon *ngIf="report.solved" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="!report.solved" color="warn">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="classification">
      <th mat-header-cell *matHeaderCellDef>Classification</th>
      <td mat-cell *matCellDef="let report">
        {{ report.classification | classification }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showReport(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
