import * as moment from 'moment';

const DTO_DATE_FORMAT = "DD-MM-YYYY";
const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKEND_DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm:ss";

export function formatDateForBackend(date: Date, includeTime: boolean = false) {
  if (date) {
    return moment(date).format(includeTime ? BACKEND_DATE_FORMAT_WITH_TIME : BACKEND_DATE_FORMAT);
  }
  return null;
}

export function dateFromDTO(value: string): Date {
  if (value) {
    return moment(value, BACKEND_DATE_FORMAT_WITH_TIME).toDate();
  }
  return null;
}

export function splitMinutes(value: number): { days: number, hours: number, minutes: number } {
  const days = Math.floor(value / (24 * 60));
  const hours = Math.floor((value - days * 24 * 60) / 60);
  const minutes = value - days * 24 * 60 - hours * 60;
  return { days, hours, minutes };
}

export function startOfMonth(date?: Date): Date {
  return moment(date).startOf('month').toDate();
}

export function endOfMonth(date?: Date): Date {
  return moment(date).endOf('month').toDate();
}

export function addDays(date: Date, days: number): Date {
  return moment(date).add(days, 'days').toDate();
}

export function substractDays(date: Date, days: number): Date {
  return moment(date).subtract(days, 'days').toDate();
}
