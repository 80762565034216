import { Action, createReducer, on } from '@ngrx/store';

import * as ReportActions from '../actions/report.actions';
import { ReportFilters, Report, ReportDTO, defaultReportFilters, ReportGraphData } from 'src/app/commons/models/report.model';

export interface ReportState {
  list: ReportDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ReportFilters,
  dialogId: string,
  report: Report,
  graphData: ReportGraphData,
};

const initialState: ReportState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  report: null,
  graphData: null,
};

const userReducer = createReducer(
  initialState,
  on(ReportActions.loadReportsCompleted, (state, { reports, currentPage, total, perPage, order, direction, includes }): ReportState => {
    return { ...state, list: reports, currentPage, total, perPage, order, direction, includes };
  }),
  on(ReportActions.reportDialogOpened, (state, { dialogId }): ReportState => {
    return { ...state, dialogId };
  }),
  on(ReportActions.changeFilters, (state, { filters }): ReportState => {
    return { ...state, currentPage: 1, filters: { ...state.filters, ...filters } };
  }),
  on(ReportActions.showReports, (state, { user }): ReportState => {
    return { ...state, filters: { ...state.filters, user: user ?? state.filters?.user } };
  }),
  on(ReportActions.loadReportsGraphCompleted, (state, { result: graphData }): ReportState => {
    return { ...state, graphData };
  })
);

export function reducer(state: ReportState | undefined, action: Action) {
  return userReducer(state, action);
}

