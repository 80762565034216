import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routerGo } from 'src/app/store/actions/router.actions';
import * as SidebarActions from 'src/app/store/actions/sidebar.actions';
import { AppState } from 'src/app/store/reducers';
import * as SidebarSelector from 'src/app/store/selectors/sidebar.selector';
import { VERSION } from 'src/environments/version';


@Component({
  selector: "identifai-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  version = VERSION;
  isOpen: Observable<boolean>;

  constructor(private store$: Store<AppState>, private router: Router) { }

  menuClick(path: string[]) {
    this.store$.dispatch(routerGo({ path: path }));
    this.toggleSidebar();
  }

  openNewTab(path: string[]) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(path)
    );

    window.open(url, '_blank');
  }

  ngOnInit() {
    this.isOpen = this.store$.pipe(select(SidebarSelector.getIsToggled), takeUntil(this.unsubscribe$));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private toggleSidebar() {
    this.store$.dispatch(SidebarActions.sidebarToggle());
  }
}
