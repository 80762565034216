import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { VerdictDTO } from 'src/app/commons/models/image.model';
import { Request } from 'src/app/commons/models/request.model';

@Component({
  selector: 'identifai-verdicts',
  templateUrl: './verdicts.component.html',
  styleUrls: ['./verdicts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerdictsComponent {

  @Input()
  request: Request;

  @Input()
  currentHeuristic: string;

  @Output()
  onChangeHeuristic: EventEmitter<string> = new EventEmitter<string>();

  currentVerdict: VerdictDTO;

  ngOnChanges(_: SimpleChanges): void {
    this.currentVerdict = null;
    if (this.request?.verdicts?.length && !!this.currentHeuristic) {
      this.currentVerdict = this.request?.verdicts.find(v => v.heuristic == this.currentHeuristic);
    }
  }
}
