<header mat-dialog-title class="u-flex-full">
  <span> ToS </span>
</header>
<mat-dialog-content>
  <div class="u-full-width">
    <span>
      In order to access the app you must accept the
      <a [href]="termsOfService" target="_blank">terms of service</a>.<br />
      Please read them carefully and make sure you have understood them.
    </span>
  </div>
  <!-- {{userForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <button mat-raised-button color="primary" (click)="accept()">Accept</button>

  <button mat-raised-button color="basic" type="button" (click)="reject()">
    Reject
  </button>
</mat-dialog-actions>
