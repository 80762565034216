<div class="graph">
  <h3 class="graph__title" *ngIf="title" (click)="onTitleClick.emit()">{{title}}</h3>
  <!-- <ngx-charts-bar-vertical-stacked *ngIf="type === 'bar'" [results]="displayedData" [legend]="config.legend"
    (select)="onLegendClick($event)" [showXAxisLabel]="config.showXAxisLabel" [showYAxisLabel]="config.showYAxisLabel"
    [xAxis]="config.xAxis" [yAxis]="config.yAxis" [xAxisLabel]="config.xAxisLabel" [yAxisLabel]="config.yAxisLabel"
    [scheme]="config.scheme">
  </ngx-charts-bar-vertical-stacked>
  <ngx-charts-line-chart *ngIf="type === 'line'" [results]="displayedData" [legend]="config.legend"
    (select)="onLegendClick($event)" [showXAxisLabel]="config.showXAxisLabel" [showYAxisLabel]="config.showYAxisLabel"
    [xAxis]="config.xAxis" [yAxis]="config.yAxis" [xAxisLabel]="config.xAxisLabel" [yAxisLabel]="config.yAxisLabel"
    [scheme]="config.scheme">
  </ngx-charts-line-chart> -->
  <ng-container *ngIf="data?.datasets?.length; else nodata">
    <div chartType="bar" *ngIf="type === 'bar'" style="position: relative;">
      <canvas baseChart [labels]="data.labels" [options]="config" [datasets]="data.datasets" [type]="type">
      </canvas>
    </div>

    <div chartType="line" *ngIf="type === 'line'" style="position: relative;">
      <canvas baseChart [labels]="data.labels" [options]="config" [datasets]="data.datasets" [type]="type">
      </canvas>
    </div>
  </ng-container>
  <ng-template #nodata>
    <h4 class="u-center-text" style="opacity: .6;">No data</h4>
  </ng-template>
</div>
