<div style="height: 400px;">
  <!-- <ngx-charts-line-chart [results]="data" [legend]="true" [showYAxisLabel]="chartConfig.showYAxisLabel"
    [xAxis]="chartConfig.xAxis" [yAxis]="chartConfig.yAxis" [showXAxisLabel]="true" [yAxisLabel]="verdict + ' %'"
    [scheme]="chartConfig.scheme" [yScaleMax]="chartConfig.yScaleMax"
    [xAxisTickFormatting]="chartConfig.xAxisTickFormatting" [yScaleMin]="chartConfig.yScaleMin" [curve]="curve">
  </ngx-charts-line-chart> -->

  <identifai-multi-series-graph type="line" [data]="graphData"
    [config]="defaultGraphConfig"></identifai-multi-series-graph>

  <ng-container *showFor="['admin']">
    <div *ngIf="!!results?.std_deviations">
      <h4>Standard Deviations</h4>
      <div *ngFor="let item of results?.std_deviations | keyvalue">
        <span><i>{{ item.key }}</i>: {{ item.value | percent:'1.2-2' }}</span>
        <span matTooltip="Biased model: excluded from analysis" *ngIf="excludedModels?.includes(item.key)"
          class="badge badge--warning badge--small">
          Excluded
        </span>
      </div>
    </div>
  </ng-container>
</div>