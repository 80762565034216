import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, forwardRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { User, UserFilters } from 'src/app/commons/models/user.model';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';


export const USERS_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => UsersFieldComponent),
};

@Component({
  selector: "identifai-users-field",
  templateUrl: "./users-field.component.html",
  styleUrls: ["./users-field.component.scss"],
  providers: [USERS_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class UsersFieldComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<User[]> {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private unsubscribe$ = new Subject<void>();

  private _value: User[] = [];
  private _disabled = false;

  private _required = false;

  get errorState(): boolean {
    return !this.ngControl.valid;
  }
  focused: boolean; //TODO
  controlType?: string = "user-selection-field";
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  currentFormControlName: string;

  @Input()
  userFilters: UserFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder
  ) {
    this.actions$
      .pipe(
        ofType(UserActions.userSelected),
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.store$.select(UserSelectors.getCurrentFormControlName)),
        map(([{ user }, _]) => (user ? new User(user) : null))
      )
      .subscribe((user) => {
        // this.value = [] ;
        this.value.push(user)
        this.notifyValueChange();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  removeUser(index: number) {
    this.value.splice(index, 1);
    this.notifyValueChange();
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {

  }

  onContainerClick(event: MouseEvent): void {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectUser() {
    this.store$.dispatch(
      UserActions.selectUser({ filters: this.userFilters, currentFormControlName: this.currentFormControlName })
    );
  }

  clearUser() {
    this.value = null;
  }
  @Input()
  set value(value: User[]) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): User[] {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: User[]): void {

    this.value = [];
    if (obj) {
      this.value.push(...obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
