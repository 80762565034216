import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'c2pa_action'
})
export class C2paActionPipe implements PipeTransform {

  transform(value: string): unknown {
    switch (value) {
      case 'c2pa.color_adjustments':
        return "Changes to tone, saturation, etc."
      case "c2pa.converted":
        return "The format of the asset was changed."
      case "c2pa.created":
        return "The asset was first created, usually the asset’s origin."
      case "c2pa.cropped":
        return "Areas of the asset’s \"editorial\" content were cropped out."
      case "c2pa.drawing":
        return "Changes using drawing tools including brushes or eraser."
      case "c2pa.edited":
        return "Generalized actions that would be considered 'editorial transformations' of the content."
      case "c2pa.filtered":
        return "Changes to appearance with applied filters, styles, etc."
      case "c2pa.opened":
        return "An existing asset was opened and is being set as the parentOf ingredient."
      case "c2pa.orientation":
        return "Changes to the direction and position of content."
      case "c2pa.placed":
        return "Added/Placed a componentOf ingredient into the asset."
      case "c2pa.published":
        return "Asset is released to a wider audience."
      case "c2pa.removed":
        return "A componentOf ingredient was removed."
      case "c2pa.repackaged":
        return "A conversion of one packaging or container format to another. Content is repackaged without transcoding. This action is considered as a 'non-editorial transformation' of the parentOf ingredient."
      case "c2pa.resized":
        return "Changes to content dimensions and/or file size."
      case "c2pa.transcoded":
        return "A conversion of one encoding to another, including resolution scaling, bitrate adjustment and encoding format change. This action is considered as a 'non-editorial transformation' of the parentOf ingredient."
      case "c2pa.unknown":
        return "Something happened, but the claim_generator cannot specify what."
    }
    return value;
  }

}
