import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgChartsModule } from 'ng2-charts';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { MaterialModule } from './../material/material.module';
import { CreditFiltersComponent } from './components/credit/credit-filters/credit-filters.component';
import { CreditListComponent } from './components/credit/credit-list/credit-list.component';
import { CreditRequestComponent } from './components/credit/credit-request/credit-request.component';
import { MultiSeriesGraphComponent } from './components/graphs/multi-series-graph/multi-series-graph.component';
import { ClassificationReportComponent } from './components/report/classification-report/classification-report.component';
import { ReportFiltersComponent } from './components/report/report-filters/report-filters.component';
import { ReportInfoComponent } from './components/report/report-info/report-info.component';
import { ReportListComponent } from './components/report/report-list/report-list.component';
import { ImageModelsComponent } from './components/request/image-models/image-models.component';
import { RequestFiltersComponent } from './components/request/request-filters/request-filters.component';
import { RequestInfoComponent } from './components/request/request-info/request-info.component';
import { RequestListComponent } from './components/request/request-list/request-list.component';
import { RequestSendComponent } from './components/request/request-send/request-send.component';
import { RequestsComponent } from './components/request/requests/requests.component';
import { VerdictsComponent } from './components/request/verdicts/verdicts.component';
import { VideoModelsComponent } from './components/request/video-models/video-models.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopnavComponent } from './components/topnav/topnav.component';
import { ApiKeyListComponent } from './components/user/api-key-list/api-key-list.component';
import { ApiKeyComponent } from './components/user/api-key/api-key.component';
import { PasswordChangeComponent } from './components/user/password-change/password-change.component';
import { TermsOfServiceComponent } from './components/user/tos/tos.component';
import { UserEditComponent } from './components/user/user-edit/user-edit.component';
import { UserFieldComponent } from './components/user/user-field/user-field.component';
import { UserFiltersComponent } from './components/user/user-filters/user-filters.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserSelectionComponent } from './components/user/user-selection/user-selection.component';
import { UsersFieldComponent } from './components/user/users-field/users-field.component';
import { ShowForDirective } from './directives/show-for.directive';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { BooleanPipe } from './pipes/boolean-pipe';
import { ByteFormatPipe } from './pipes/byte-format.pipe';
import { C2paActionPipe } from './pipes/c2pa-pipe';
import { ClassificationPipe } from './pipes/classification-pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { FileTypePipe } from './pipes/file-type-pipe';
import { HeuristicPipe } from './pipes/heuristic.pipe';
import { PrettyjsonPipe } from './pipes/prettyjson.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SortVerdictsPipe } from './pipes/sort-verdicts.pipe';
import { UserRolePipe } from './pipes/user-role.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    MatDialogModule,
    NgxChartsModule,
    NgxJsonViewerModule,
    NgChartsModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    UserRolePipe,
    SafeUrlPipe,
    DateFormatPipe,
    ByteFormatPipe,
    UserListComponent,
    UserFiltersComponent,
    UserSelectionComponent,
    UserFieldComponent,
    UsersFieldComponent,
    UserEditComponent,
    PasswordChangeComponent,
    ApiKeyComponent,
    CreditListComponent,
    CreditFiltersComponent,
    CreditRequestComponent,
    RequestSendComponent,
    RequestListComponent,
    RequestFiltersComponent,
    RequestInfoComponent,
    RequestsComponent,
    ClassificationReportComponent,
    ReportFiltersComponent,
    ReportListComponent,
    ReportInfoComponent,
    ApiKeyListComponent,
    TermsOfServiceComponent,
    PrettyjsonPipe,
    ClassificationPipe,
    FileTypePipe,
    BooleanPipe,
    TopnavComponent,
    SidebarComponent,
    ShowForDirective,
    ConfirmDialogComponent,
    C2paActionPipe,
    ImageModelsComponent,
    VideoModelsComponent,
    VerdictsComponent,
    HeuristicPipe,
    SortVerdictsPipe,
    NgxChartsModule,
    NgChartsModule,
    MultiSeriesGraphComponent
  ],
  declarations: [
    TopnavComponent,
    SidebarComponent,
    UserListComponent,
    UserFiltersComponent,
    UserSelectionComponent,
    UserFieldComponent,
    UsersFieldComponent,
    UserEditComponent,
    PasswordChangeComponent,
    ApiKeyComponent,
    CreditListComponent,
    CreditFiltersComponent,
    CreditRequestComponent,
    RequestSendComponent,
    RequestListComponent,
    RequestFiltersComponent,
    RequestInfoComponent,
    RequestsComponent,
    ClassificationReportComponent,
    ReportFiltersComponent,
    ReportListComponent,
    ReportInfoComponent,
    ApiKeyListComponent,
    TermsOfServiceComponent,
    ImageModelsComponent,
    VideoModelsComponent,
    UserRolePipe,
    SafeUrlPipe,
    PrettyjsonPipe,
    DateFormatPipe,
    ByteFormatPipe,
    ClassificationPipe,
    FileTypePipe,
    BooleanPipe,
    C2paActionPipe,
    HeuristicPipe,
    VerdictsComponent,
    SortVerdictsPipe,
    ShowForDirective,
    ConfirmDialogComponent,
    MultiSeriesGraphComponent
  ],
  providers: []
})
export class SharedModule { }
