import { Component } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { RequestType } from 'src/app/commons/models/request.model';
import {
  IMG_MAX_SIZE,
  IMG_VALID_EXTENSIONS,
  VIDEO_MAX_SIZE,
  VIDEO_VALID_EXTENSIONS,
} from 'src/app/constants/media.constants';
import { fileExtensionValidator, fileSizeValidator } from 'src/app/helpers/validators/file.validator';
import * as RequestActions from 'src/app/store/actions/request.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: "identifai-request-send",
  templateUrl: "./request-send.component.html",
  styleUrls: ["./request-send.component.scss"],
})
export class RequestSendComponent {
  private unsubscribe$ = new Subject<void>();

  imageCtrl: UntypedFormControl = new UntypedFormControl(null, [
    Validators.required, fileExtensionValidator(IMG_VALID_EXTENSIONS), fileSizeValidator(IMG_MAX_SIZE)
  ]);

  videoCtrl: UntypedFormControl = new UntypedFormControl(null, [
    Validators.required, fileExtensionValidator(VIDEO_VALID_EXTENSIONS), fileSizeValidator(VIDEO_MAX_SIZE)
  ]);

  framesCtrl: FormControl<number> = new FormControl(5, [
    Validators.required
  ])

  type: RequestType = "image";

  // maxSize = MAX_SIZE;
  // acceptsString: string = VALID_EXTENSIONS.map((v) => `.${v}`).join(',')

  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  send() {
    let image = this.imageCtrl.value;
    let video = this.videoCtrl.value;
    const file = this.type === "image" ? image : video;
    this.store$.dispatch(RequestActions.sendRequest({ file, requestType: this.type, frames: this.framesCtrl.value }));
  }

  get maxSize(): number {
    return this.type === "image" ? IMG_MAX_SIZE : VIDEO_MAX_SIZE;
  }

  get acceptsString(): string {
    const EXTS = this.type === "image" ? IMG_VALID_EXTENSIONS : VIDEO_VALID_EXTENSIONS;
    return EXTS.map((v) => `.${v}`).join(',')
  }

  close() {
    this.store$.dispatch(RequestActions.closeRequestDialog());
  }
}
