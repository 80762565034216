import { Component, Inject, OnDestroy, OnInit, Optional } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { select, Store } from "@ngrx/store";
import { Subject, map, takeUntil } from "rxjs";
import { Report } from "src/app/commons/models/report.model";
import { User } from "src/app/commons/models/user.model";
import * as ReportActions from "src/app/store/actions/report.actions";
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "identifai-report-info",
  templateUrl: "./report-info.component.html",
  styleUrls: ["./report-info.component.scss"],
})
export class ReportInfoComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  report: Report;
  currentUser: User;

  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.report = this.data.report;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  approve() {
    this.store$.dispatch(ReportActions.approveReport({ id: this.report.id }));
  }

  decline() {
    this.store$.dispatch(ReportActions.declineReport({ id: this.report.id }));
  }

  close() {
    this.store$.dispatch(ReportActions.closeReportDialog());
  }
}
