import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import * as AuthActions from 'src/app/store/actions/auth.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import { UNAUTH_ROUTES } from '../constants/auth.constants';

@Injectable({
  providedIn: "root"
})
export class CredentialsInterceptorService implements HttpInterceptor {
  constructor(private store: Store<AppState>,) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedReq = this.credentialsInclude(req);
    return next.handle(clonedReq).pipe(
      map(resp => {
        // on Response
        if (resp instanceof HttpResponse) {
          // Do whatever you want with the response.
          return resp;
        }
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && !UNAUTH_ROUTES.includes(window.location.pathname)) {
            this.store.dispatch(AuthActions.logoutCompleted());
          }
        }
        throw err;
      })
    );
  }

  /**
   * Adds Credentials: 'include' header
   * @param request
   * @param token
   */
  private credentialsInclude(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({ withCredentials: true });
  }
}