import { Pipe, PipeTransform } from '@angular/core';
import { VerdictDTO } from 'src/app/commons/models/image.model';

@Pipe({
  name: "sortVerdicts"
})
export class SortVerdictsPipe implements PipeTransform {
  transform(verdicts: VerdictDTO[]): VerdictDTO[] {
    if (verdicts) {
      return [...verdicts].sort((a, b) => a.heuristic < b.heuristic ? -1 : a.heuristic > b.heuristic ? 1 : 0)
    }
    return [];
  }
}
