<div class="verdicts">
  <div *ngFor="let verdict of request?.verdicts | sortVerdicts" style="padding: 5px 0">
    <div class="verdict">
      <h4>
        <span>{{verdict.heuristic | heuristic}} - {{verdict.confidence | percent}} {{verdict.classification |
          classification}}
        </span>
        <mat-icon *ngIf="verdict.classification != currentVerdict?.classification" color="accent"
          matTooltip="Different verdict compared to the selected heuristic">warning</mat-icon>
      </h4>
      <button mat-stroked-button color="primary" *ngIf="verdict.heuristic != currentHeuristic"
        (click)="onChangeHeuristic.emit(verdict.heuristic)">Use this
        heuristic</button>
      <span *ngIf="verdict.heuristic == currentHeuristic" class="badge">Current Heuristic</span>
    </div>
    <mat-progress-bar mode="determinate"
      [color]="verdict.classification != currentVerdict?.classification ? 'accent' : 'primary'"
      [value]="verdict.confidence *100"></mat-progress-bar>
  </div>
</div>