import { Action, createReducer, on } from '@ngrx/store';
import { mutableOn } from 'ngrx-etc';
import { ApiKeyLogDTO } from 'src/app/commons/models/api-key-log.model';

import { User, UserDTO, UsersGraphData } from '../../commons/models/user.model';
import * as AuthActions from '../actions/auth.actions';
import * as UserActions from '../actions/user.actions';
import { UserFilters } from './../../commons/models/user.model';

export interface UserState {
  list: UserDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: UserFilters,
  dialogId: string,
  changePasswordDialogId: string,
  apiKeyDialogId: string,
  selectionDialogId: string,
  currentFormControlName: string,
  user: User,
  apiKeys: ApiKeyLogDTO[]
  tosDialogId: string,
  graphData: UsersGraphData
};

const initialState: UserState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  changePasswordDialogId: null,
  apiKeyDialogId: null,
  selectionDialogId: null,
  currentFormControlName: null,
  user: null,
  apiKeys: [],
  tosDialogId: null,
  graphData: null
};

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsersCompleted, (state, { users, currentPage, total, perPage, order, direction, includes }): UserState => {
    return { ...state, list: users, currentPage, total, perPage, order, direction, includes };
  }),
  on(UserActions.userDialogOpened, (state, { dialogId }): UserState => {
    return { ...state, dialogId };
  }),
  on(UserActions.changePasswordDialogOpen, (state, { dialogId }): UserState => {
    return { ...state, changePasswordDialogId: dialogId };
  }),
  on(UserActions.apiKeyDialogOpened, (state, { dialogId }): UserState => {
    return { ...state, apiKeyDialogId: dialogId };
  }),
  on(UserActions.changeFilters, (state, { filters }): UserState => {
    return { ...state, currentPage: 1, filters };
  }),
  on(UserActions.selectionDialogOpened, (state, { selectionDialogId }): UserState => {
    return { ...state, selectionDialogId };
  }),
  on(UserActions.selectUser, (state, { filters, currentFormControlName }): UserState => {
    return { ...state, currentPage: 1, filters, currentFormControlName };
  }),
  on(UserActions.loadApiKeysCompleted, (state, { apiKeys }): UserState => {
    return { ...state, apiKeys };
  }),
  on(UserActions.tosDialogOpened, (state, { dialogId }): UserState => {
    return { ...state, tosDialogId: dialogId };
  }),
  on(UserActions.loadUsersGraphCompleted, (state, { result: graphData }): UserState => {
    return { ...state, graphData };
  }),
  mutableOn(AuthActions.updateHeuristicCompleted, (state, { user }) => {
    const existing = state.list?.find(u => u.id === user.id);
    if (existing) {
      existing.heuristic = user.heuristic;
    }
  }),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

