import { Pipe, PipeTransform } from '@angular/core';
import { RequestType } from 'src/app/commons/models/request.model';

@Pipe({
  name: 'file_type'
})
export class FileTypePipe implements PipeTransform {

  transform(value: RequestType): unknown {
    switch (value) {
      case 'video':
        return 'Video';
      case 'image':
        return 'Image';
    }
  }

}
