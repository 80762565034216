import { Component, Inject, OnChanges, OnDestroy, OnInit, Optional } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { AlertService } from "src/app/commons/services/alert.service";
import * as UserActions from "src/app/store/actions/user.actions";
import { AppState } from "src/app/store/reducers";
import { environment } from "src/environments/environment";

@Component({
  selector: "identifai-tos",
  templateUrl: "./tos.component.html",
  styleUrls: ["./tos.component.scss"],
})
export class TermsOfServiceComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  termsOfService = environment.termOfService;

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  accept() {
    this.alertService
      .showConfirmDialog(
        "Terms of Service",
        `This choice cannot be undone. Are you sure you want to accept the Terms of Service?`
      )
      .subscribe((result) => {
        if (result) {
          this.store$.dispatch(UserActions.acceptTos());
        }
      })
  }

  reject() {
    this.alertService
      .showConfirmDialog(
        "Reject",
        "Are you sure you want to reject ToS?<br>You will be logged out and you won't be able to use the app."
      )
      .subscribe((result) => {
        if (result) {
          this.store$.dispatch(UserActions.rejectTos());
        }
      });
  }
}
