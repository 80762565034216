import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Returns a validator function that checks if a password meets certain criteria.
 *
 * @returns {ValidatorFn} A validator function that takes a form control and returns null if the password is valid, or a ValidationErrors object if the password is invalid.
 */
export function passwordValidator(control: AbstractControl): ValidationErrors | null {
    let value: string = control.value || '';

    if (!value) {
        return null
    }

    let upperCaseCharacters = /[A-Z]+/g
    if (upperCaseCharacters.test(value) === false) {
        return { error: `Password must contain an upper case character` };
    }

    let lowerCaseCharacters = /[a-z]+/g
    if (lowerCaseCharacters.test(value) === false) {
        return { error: `Password must contain a lower case character` };
    }

    let numberCharacters = /[0-9]+/g
    if (numberCharacters.test(value) === false) {
        return { error: `Password must contain a number` };
    }

    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g
    if (specialCharacters.test(value) === false) {
        return { error: `Password must contain special character` };
    }

    if (value.length < 8) {
        return { error: `Password must be at least 8 characters long` };
    }

    // check for three consecutive characters
    if (value.match(/(.)\1\1/)) {
        return { error: `Password must not contain three or more consecutive characters` };
    }

    return null;
};

export function checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
) {
    return (group: UntypedFormGroup) => {
        let passwordInput = group.controls[passwordKey],
            passwordConfirmationInput = group.controls[passwordConfirmationKey];
        if (passwordInput.value !== passwordConfirmationInput.value) {
            passwordConfirmationInput.setErrors({ notEquivalent: true });
            return { notEquivalent: true };
        } else {
            passwordConfirmationInput.setErrors(null);
            return null;
        }
    };
}
