import { createAction, props } from '@ngrx/store';
import { ImagesGraphData } from 'src/app/commons/models/image.model';
import { VideosGraphData } from 'src/app/commons/models/video.model';

export const loadImagesGraph = createAction('[Images] Load requests graph');
export const loadImagesGraphCompleted = createAction('[Images] Load requests graph Completed', props<{ result: ImagesGraphData }>());
export const loadImagesGraphFailed = createAction('[Images] Load requests graph Failed', props<{ error: any }>());

export const loadVideosGraph = createAction('[Videos] Load requests graph');
export const loadVideosGraphCompleted = createAction('[Videos] Load requests graph Completed', props<{ result: VideosGraphData }>());
export const loadVideosGraphFailed = createAction('[Videos] Load requests graph Failed', props<{ error: any }>());
