import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { curveBasis } from 'd3-shape';
import { ClassificationType } from 'src/app/commons/models/request.model';
import { VideoClassificationResults } from 'src/app/commons/models/video.model';
import { deepMerge, DEFAULT_OPTIONS, GRAPH_PALETTE, GraphData } from 'src/app/helpers/graph.utils';

@Component({
  selector: 'identifai-video-models',
  templateUrl: './video-models.component.html',
  styleUrls: ['./video-models.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoModelsComponent {

  curve = curveBasis;

  @Input()
  results: VideoClassificationResults;

  @Input()
  excludedModels: string[] = [];

  @Input()
  verdict: ClassificationType = 'human';

  defaultGraphConfig: ChartOptions = deepMerge(DEFAULT_OPTIONS, {
    scales: {
      y: {
        stacked: false,
        max: 100,
        min: 0
      },
      x: {
        stacked: false,
        ticks: {
          display: false
        },
        title: {
          text: "Time"
        }
      }
    }
  });

  graphData: GraphData;

  ngOnChanges(_: SimpleChanges): void {
    if (this.verdict) {
      this.defaultGraphConfig.scales.y['title'].text = `${this.verdict} %`;
    }
    if (this.results && this.verdict) {

      const labels = Array.from({ length: this.results.images.length }, (_, index) => `frame_#${index + 1}`);
      const datasets = [];
      this.results.images.forEach((classifications) => {

        classifications.forEach((classification, index) => {
          const rightValue = classification.classification.find(c => c.label === this.verdict).score * 100;
          const paletteIndex = index % 2 === 0 ? index % GRAPH_PALETTE.length : Math.abs(GRAPH_PALETTE.length - index) % GRAPH_PALETTE.length;

          let dataset: ChartDataset = datasets.find(d => d.label === classification.model);
          if (!dataset) {
            dataset = {
              data: [rightValue],
              label: classification.model,
              backgroundColor: GRAPH_PALETTE[paletteIndex],
              borderColor: GRAPH_PALETTE[paletteIndex],
              pointBorderColor: GRAPH_PALETTE[paletteIndex],
              pointBackgroundColor: GRAPH_PALETTE[paletteIndex],
              tension: 0.5
            }
            datasets.push(dataset);
          } else {
            dataset.data.push(rightValue);
          }
        })

      })
      // Convert the result map to an array
      this.graphData = {
        labels,
        datasets
      }
    }

  }

}

