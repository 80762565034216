<div [hidden]="!(apiKeys | async).length" class="table-container">
  <table mat-table matSort [dataSource]="apiKeys">
    <ng-container matColumnDef="apikey">
      <th mat-header-cell *matHeaderCellDef>ApiKey</th>
      <td mat-cell *matCellDef="let row" style="text-align: center">
        <input
          matInput
          [type]="showRows ? 'text' : 'password'"
          [value]="row.apiKey"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="created_at">
        Created At
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.createdAt | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <mat-icon (click)="showRows = !showRows">
          {{ showRows ? "visibility_off" : "visibility" }}
        </mat-icon>
      </th>
      <td mat-cell *matCellDef="let row"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
