import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReportFilters } from 'src/app/commons/models/report.model';

@Component({
  selector: 'identifai-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.scss']
})
export class ReportFiltersComponent {
  @Output()
  onFilter: EventEmitter<ReportFilters> = new EventEmitter();

  private _defaultFilters: ReportFilters;
  filtersForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      hash: "",
      user: "",
      solved: "",
      type: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getReportFilters());
  }

  private _getReportFilters(): ReportFilters {
    let filters: ReportFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.user = values.user;
      filters.hash = values.hash;
      filters.solved = values.solved;
      filters.type = values.type;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      hash: "",
      user: null,
      solved: null,
      type: null
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ReportFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ReportFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          user: this.defaultFilters.user,
          hash: this.defaultFilters.hash,
          solved: this.defaultFilters.solved,
          type: this.defaultFilters.type
        })
      }
    }
  }
}
