import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { GraphData } from 'src/app/helpers/graph.utils';

export type GraphType = 'bar' | 'line';

@Component({
  selector: 'identifai-multi-series-graph',
  templateUrl: './multi-series-graph.component.html',
  styleUrls: ['./multi-series-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSeriesGraphComponent {


  @Input()
  type: GraphType = 'bar';

  @Input()
  stacked: boolean = false;

  @Input()
  title: string;

  // @Input()
  // allData: GraphMultiSeries[];

  @Input()
  config: ChartOptions;

  @Input()
  data: GraphData;

  @Output()
  onTitleClick = new EventEmitter<void>();
}
