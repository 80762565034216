import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { User } from 'src/app/commons/models/user.model';
import { BIG_PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import { Credit, CreditFilters } from 'src/app/commons/models/credit.model';

export type CreditColumn = "id" | "date" | "positive" | "negative" | "user" | "hash" | "description" | "actions";

@Component({
  selector: 'credit-list',
  templateUrl: './credit-list.component.html',
  styleUrls: ['./credit-list.component.scss']
})
export class CreditListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  isAdmin: boolean;

  @Input()
  credits: Observable<Credit[]>;

  @Input()
  defaultFilters: CreditFilters;

  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: CreditColumn[] = ["date", "positive", "negative", "hash", "description"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<CreditFilters> = new EventEmitter<CreditFilters>();

  @Output()
  onEditCredit: EventEmitter<{ credit: Credit, user?: User }> = new EventEmitter<{ credit: Credit, user?: User }>();

  constructor() { }

  ngOnInit() {
    if (this.isAdmin) {
      this.displayedColumns.push('user');
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }


  editCredit(credit: Credit) {
    this.onEditCredit.emit({ credit });
  }

  addCredit() {
    this.onEditCredit.emit({ credit: null });
  }

  onFilter(filters: CreditFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
