<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="confirmMessage"></p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
    Confirm
  </button>
  <button mat-raised-button (click)="dialogRef.close(false)">Discard</button>
</div>
