<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> Filter </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Search</mat-label>
        <input type="text" matInput formControlName="search" />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-select formControlName="roles" multiple>
          <mat-select-trigger>
            <span *ngIf="filtersForm.value.roles?.length === roles.length"
              >All</span
            >
            <span *ngIf="filtersForm.value.roles?.length < roles.length">
              {{
                (filtersForm.value.roles ? filtersForm.value.roles[0] : "")
                  | userRole
              }}
              <span
                *ngIf="filtersForm.value.roles?.length > 1"
                class="additional-selection"
              >
                (+{{ filtersForm.value.roles.length - 1 }}
                other)
              </span>
            </span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let role of roles"
            [value]="role"
            [ngClass]="role"
          >
            {{ role | userRole }}</mat-option
          >
        </mat-select>
        <mat-hint>Role</mat-hint>
      </mat-form-field>
      <span class="u-flex-full">
        <button
          class="filter"
          mat-raised-button
          [disabled]="filtersForm.pristine"
          color="primary"
          (click)="applyFilters()"
        >
          Filter
        </button>
        <button
          mat-icon-button
          *ngIf="!filtersForm.pristine"
          (click)="resetFilters()"
          matTooltip="Reset"
        >
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>
