import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RequestFilters } from 'src/app/commons/models/request.model';

@Component({
  selector: 'request-filters',
  templateUrl: './request-filters.component.html',
  styleUrls: ['./request-filters.component.scss']
})
export class RequestFiltersComponent {
  @Output()
  onFilter: EventEmitter<RequestFilters> = new EventEmitter();

  private _defaultFilters: RequestFilters;

  filtersForm: UntypedFormGroup;
  // maxSize = MAX_SIZE;
  // acceptsString: string = VALID_EXTENSIONS.map((v) => `.${v}`).join(',')

  constructor(private fb: UntypedFormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      user: [""],
      search: [""],
      classification: [""],
      version: [""],
      type: [""],
      // image: [null, [fileExtensionValidator(VALID_EXTENSIONS), fileSizeValidator(MAX_SIZE)]]
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getRequestFilters());
  }

  private _getRequestFilters(): RequestFilters {
    let filters: RequestFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.user = values.user;
      filters.search = values.search;
      filters.classification = values.classification;
      filters.version = values.version;
      // filters.image = values.image;
      filters.type = values.type;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      user: null,
      search: "",
      classification: "",
      version: "",
      // image: null,
      type: ""
    });
    this.applyFilters();
  }

  get defaultFilters(): RequestFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: RequestFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          user: this.defaultFilters.user,
          search: this.defaultFilters.search,
          classification: this.defaultFilters.classification,
          version: this.defaultFilters.version,
          // image: this.defaultFilters.image,
          type: this.defaultFilters.type,
        })
      }
    }
  }
}
