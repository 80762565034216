<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> Filter </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div>
      <div class="u-flex-full">
        <mat-form-field floatLabel="always" class="u-full-width">
          <mat-label>Hash</mat-label>
          <input type="text" matInput formControlName="hash" />
        </mat-form-field>

        <mat-form-field class="u-full-width">
          <mat-label>User</mat-label>
          <identifai-user-field formControlName="user"></identifai-user-field>
          <mat-error>Field required</mat-error>
        </mat-form-field>

        <mat-form-field class="u-full-width">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option value="">-- All --</mat-option>
            <mat-option value="image">Image</mat-option>
            <mat-option value="video">Video</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="u-full-width">
          <mat-label>Solved</mat-label>
          <mat-select formControlName="solved">
            <mat-option value="">-- All --</mat-option>
            <mat-option [value]="true">Solved</mat-option>
            <mat-option [value]="false">Not Solved</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">
          Filter
        </button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>
