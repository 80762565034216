import { createAction, props } from '@ngrx/store';

import { UserDTO } from '../../commons/models/user.model';

export const login = createAction('[Auth] Login', props<{ username: string, password: string }>());
export const loginCompleted = createAction('[Auth] Login Completed', props<{ currentUser: UserDTO }>());
export const loginFailed = createAction('[Auth] Login Failed', props<{ error: any }>());

export const register = createAction('[Auth] Register', props<{ name: string, email: string, password: string, tos: boolean }>());
export const registerCompleted = createAction('[Auth] Register Completed');
export const registerFailed = createAction('[Auth] Register Failed', props<{ error: any }>());

export const logout = createAction('[Auth] Logout', props<{ showConfirm: boolean }>());
export const logoutFailed = createAction('[Auth] Logout Failed', props<{ error: any }>());
export const logoutCompleted = createAction('[Auth] Logout Completed');
export const logoutCancelled = createAction('[Auth] Logout Cancelled');

export const loadCurrentUser = createAction('[Auth] Load current user');
export const loadCurrentUserCompleted = createAction('[Auth] Load current user completed', props<{ currentUser: UserDTO }>());
export const loadCurrentUserFailed = createAction('[Auth] Load current user failed', props<{ error: any }>());

export const loadAvailabelHeuristics = createAction('[Auth] Load available heuristics');
export const loadAvailabelHeuristicsCompleted = createAction('[Auth] Load available heuristics completed', props<{ heuristics: string[] }>());
export const loadAvailabelHeuristicsFailed = createAction('[Auth] Load available heuristics failed', props<{ error: any }>());

export const updateCurrentHeuristic = createAction('[Auth] Update heuristic for current user', props<{ heuristic: string }>());
export const updateHeuristic = createAction('[Auth] Update heuristic', props<{ userId: number, heuristic: string }>());
export const updateHeuristicCompleted = createAction('[Auth] Update heuristic completed', props<{ user: UserDTO }>());
export const updateHeuristicFailed = createAction('[Auth] Update heuristic failed', props<{ error: any }>());

export const forgotPassword = createAction('[Auth] Forgot password', props<{ email: string }>());
export const forgotPasswordCompleted = createAction('[Auth] Forgot password completed');
export const forgotPasswordFailed = createAction('[Auth] Forgot password failed', props<{ error: any }>());

export const resetPassword = createAction('[Auth] Reset password', props<{ token: string, email: string, password: string, passwordConfirmation: string }>());
export const resetPasswordCompleted = createAction('[Auth] Reset password completed');
export const resetPasswordFailed = createAction('[Auth] Reset password failed', props<{ error: any }>());

export const verifyEmail = createAction('[Auth] Verify email', props<{ token: string }>());
export const verifyEmailCompleted = createAction('[Auth] Verify email completed');
export const verifyEmailFailed = createAction('[Auth] Verify email failed', props<{ error: any }>());
